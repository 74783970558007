/**
 * GDPR Banner
 *
 * @author Daniele De Nobili
 */

(function (d, w) {
    "use strict";

    function readCookie(name) {
        var
            nameEQ = name + '=',
            ca = d.cookie.split(';'),
            i, l = ca.length, c;

        for (i = 0; i < l; i++) {
            c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    function createCookie(name, value, days) {
        var date, expires = '';

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        d.cookie = name + '=' + value + expires + '; path=/';
    }

    /**
     * Configurazione
     */
    var config = w._GDPR || {};

    /**
     * Altre variabili necessarie allo script
     */
    var cookieName = config.cookieName || 'cookie_consent';
    var consentGiven = readCookie(cookieName);
    var listeners = [];

    w.onCookiePolicyAccepted = function (callback) {
        if (typeof callback !== 'function') {
            throw "window.onCookiePolicyAccepted() accept only functions";
        }

        if (consentGiven) {
            callback();
        } else {
            listeners.push(callback);
        }
    };

    // Google Tag manager
    w.onCookiePolicyAccepted(function () {
        w.dataLayer = w.dataLayer || [];

        w.dataLayer.push({
            'event': config.gtmCookieConsentGiven || 'cookie_consent_given'
        });
    });

    // Polyfill addClass & removeClass (IE 9)
    function addClass(element, className) {
        if (element.classList) {
            element.classList.add(className);
        } else {
            if ((" " + element.className + " ").indexOf(" " + className + " ") < 0) {
                element.className += " " + className;
            }
        }
    }

    function removeClass(element, className) {
        if (element.classList) {
            element.classList.remove(className);
        } else {
            var regex = new RegExp("(^| )" + className + "($| )", "g");
            element.className = element.className.replace(regex, " ");
        }
    }

    function hasClass(element, className) {
        if (element.classList) {
            return element.classList.contains(className);
        }

        return (" " + element.className + " ").indexOf(" " + className + " ") < 0;
    }

    function duplicateScript(original, src, text) {
        var newScript = d.createElement('script');

        if (src) {
            newScript.src = src;
        }

        if (text) {
            newScript.text = text;
        }

        original.parentNode.insertBefore(newScript, original);

        if (original.hasOwnProperty('remove')) {
            original.remove();
        }
    }

    d.addEventListener('DOMContentLoaded', function () {
        var banner = d.getElementById('js-gdpr-banner');
        var btn = d.getElementById('js-gdpr-banner-accept');
        var html = d.getElementsByTagName('html')[0];
        var modal = d.querySelector('.gdpr-modal');
        var iframeWrapper = d.querySelector('.gdpr-modal__iframe');

        if (!consentGiven) {
            // Fix iframe
            [].slice.call(document.getElementsByTagName('iframe')).forEach(
                function (iframe) {
                    if (!iframe.src && iframe.hasAttribute('data-alert')) {
                        var doc = iframe.contentWindow.document;

                        // <html style="">
                        doc.getElementsByTagName('html')[0].setAttribute('style', '-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;font:16px/1.5 system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol');

                        // <head><style>
                        var style = doc.createElement('style');
                        style.textContent = '*{margin:0;padding:0}svg:not(:root){overflow:hidden}';
                        doc.head.appendChild(style);

                        // <body style="">
                        doc.body.setAttribute('style', '-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;background:#f1f1f1;overflow:hidden;position:relative;text-align:center');

                        // <div>
                        var container = doc.createElement('div');
                        container.setAttribute('style', 'left:50%;position:absolute;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:90%');

                        // <svg>
                        var svg = doc.createElementNS('http://www.w3.org/2000/svg', 'svg');
                        svg.setAttribute('viewBox', '0 0 24 24');
                        svg.setAttribute('style', 'display:block;fill:currentColor;height:2em;margin:0 auto 1em;width:2em');
                        var path = doc.createElementNS(svg.namespaceURI, 'path');
                        path.setAttribute('d', 'M23.999 12.149c-.049 3.834-1.893 7.223-4.706 9.378-1.993 1.53-4.485 2.449-7.198 2.473-6.464.057-12.051-5.107-12.095-12 3.966 1.066 7.682-1.993 6-6 4.668.655 6.859-2.389 6.077-6 6.724.064 11.999 5.542 11.922 12.149zm-15.576-4.123c-.065 3.393-2.801 5.868-6.182 6.166 1.008 4.489 5.015 7.807 9.759 7.807 5.262 0 9.576-4.072 9.97-9.229.369-4.818-2.755-9.357-7.796-10.534-.277 2.908-2.381 5.357-5.751 5.79zm5.077 8.974c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-5.5-2.853c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm10-2.147c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm2.5-5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-12.5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-1.5-4c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm6-2c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-3.5-1c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z');
                        svg.appendChild(path);
                        container.appendChild(svg);

                        container.appendChild(document.createTextNode(iframe.getAttribute('data-alert')));
                        doc.body.appendChild(container);
                    }
                }
            );
        }

        function accept() {
            // Chiudo il banner ed imposto il cookie
            consentGiven = true;
            createCookie(cookieName, 1, config.cookieExpire || 30);

            if (banner) {
                addClass(banner, 'gdpr-banner--hide');
            }

            // Fix iframe
            [].slice.call(document.getElementsByTagName('iframe')).forEach(
                function (iframe) {
                    if (iframe.hasAttribute('data-src') && hasClass(iframe, '_gdpr_iframe')) {
                        iframe.src = iframe.getAttribute('data-src');
                        removeClass(iframe, '_gdpr_iframe');
                    }
                }
            );

            // Fix JS
            [].slice.call(document.getElementsByTagName('script')).forEach(
                function (script) {
                    if (script.hasAttribute('data-src') && hasClass(script, '_gdpr_script_src')) {
                        duplicateScript(script, script.getAttribute('data-src'));
                    } else if (hasClass(script, '_gdpr_script_inline')) {
                        duplicateScript(script, '', script.text || script.textContent || script.innerHTML || '');
                    }
                }
            );

            // Eseguo le callback registrate attraverso la onCookiePolicyAccepted()
            listeners.forEach(function (callback) {
                callback();
            });
        }

        if (consentGiven) {
            accept();
        } else if (banner) {
            setTimeout(function () {
                removeClass(banner, 'gdpr-banner--hide');
            });
        }

        if (btn) {
            btn.addEventListener('click', function (event) {
                event.preventDefault();
                accept();
            });
        }

        // Verifico la presenza del banner prima di proseguire
        // @link https://gitlab.metaline.it/metaline/GDPR/issues/7
        if (!banner) {
            return;
        }

        [].slice.call(banner.getElementsByTagName('a')).forEach(
            function (a) {
                a.addEventListener('click', function (event) {
                    event.preventDefault();

                    // Visualizzo la finestra modale
                    // Devo farlo subito altrimenti getComputedStyle() non funziona correttamente
                    modal.style.display = 'block';
                    addClass(modal, 'is-visible');

                    // Calcolo alcune variabili necessarie per impostare le dimensioni di wrapper ed iframe
                    var iframeWrapperStyle = getComputedStyle(iframeWrapper);
                    var iframeWrapperHeight = parseInt(iframeWrapperStyle.height, 10);
                    var iframeHeight = iframeWrapperHeight - parseInt(iframeWrapperStyle.paddingBottom, 10);
                    var width = parseInt(iframeWrapperStyle.width, 10);

                    // Creo l’iframe
                    var iframe = d.createElement('iframe');
                    iframe.setAttribute('src', a.href + (a.href.indexOf('?') ? '&' : '?') + 'height=' + iframeHeight);
                    iframe.setAttribute('scrolling', 'no');
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allowtransparency', 'true');

                    // Dimensioni di wrapper ed iframe
                    iframeWrapper.style.height = iframeWrapperHeight + 'px';
                    iframeWrapper.style.width = width + 'px';
                    iframe.style.height = iframeHeight + 'px';
                    iframe.style.width = width + 'px';

                    // Appendo l’iframe al wrapper
                    iframeWrapper.appendChild(iframe);

                    // Evito lo scroll della pagina sottostante
                    addClass(html, 'is-overlay-active');

                    // Chiude la finestra modale
                    function close() {
                        // Rimuovo la finestra modale
                        iframeWrapper.removeChild(iframe);

                        // Nascondo la finestra modale
                        modal.style.display = 'none';
                        removeClass(modal, 'is-visible');

                        // Ripristino lo scroll sulla pagina sottostante
                        removeClass(html, 'is-overlay-active');
                    }

                    // Pulsante "Chiudi"
                    d.querySelector('.gdpr-modal__close').addEventListener('click', close);

                    // Pulsante "Accetto"
                    d.querySelector('.gdpr-modal__accept').addEventListener('click', function () {
                        close();
                        accept();
                    });
                });
            }
        );
    });
}(document, window));
